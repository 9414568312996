import {Link} from 'gatsby';
import React, {FC, useEffect, useRef} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import {Col, Row} from 'react-bootstrap';

import Layout from '../../components/layout';
import Benefit from '../../components/shared-components/benefits';
// import atob from 'atob';

// import {pagehero1} from '../../assets/careers/pagehero/page-hero-1';
// import {pagehero2} from '../../assets/careers/pagehero/page-hero-2';
// import pageheroMobile from '../../assets/careers/pagehero/page-hero-mobile.png';

// import {reason1} from '../../assets/careers/pagehero/reason-1';
// import {reason2} from '../../assets/careers/pagehero/reason-2';
// import {reason3} from '../../assets/careers/pagehero/reason-3';

import {developmentIcon, marketingIcon, supportIcon} from '../../assets/svg-icons';

import flex from '../../assets/careers/benefits/flex.svg';
import bonus from '../../assets/careers/benefits/bonus.svg';
import stock from '../../assets/careers/benefits/stock.svg';
import coffee from '../../assets/careers/benefits/coffee.svg';
import reward from '../../assets/careers/benefits/reward.svg';
import salary from '../../assets/careers/benefits/salary.svg';
import daysOff from '../../assets/careers/benefits/day-off.svg';
import referral from '../../assets/careers/benefits/referral.svg';
import training from '../../assets/careers/benefits/training.svg';
import insurance from '../../assets/careers/benefits/insurance.svg';
import luckyCarrot from '../../assets/careers/benefits/lucky-carrot.svg';
import teambuilding from '../../assets/careers/benefits/teambuilding.svg';

import vahe from '../../assets/careers/bottom-images/vahe.png';
import nika from '../../assets/careers/bottom-images/nika.png';
import comp from '../../assets/careers/bottom-images/comp.png';
import lusine from '../../assets/careers/bottom-images/lusine.png';
import gevorg from '../../assets/careers/bottom-images/gevorg.png';
import stepan from '../../assets/careers/bottom-images/stepan.png';
import wall from '../../assets/careers/bottom-images/picture-wall.png';
import green from '../../assets/careers/bottom-images/bottom-icons/green.png';
import purple from '../../assets/careers/bottom-images/bottom-icons/purple.png';
import yellow from '../../assets/careers/bottom-images/bottom-icons/yellow.png';
import brainstorming from '../../assets/careers/bottom-images/brainstorming.png';
import lifeAtHexact from '../../assets/careers/bottom-images/bottom-icons/life-at-hexact.png';

import './styles.scss';

const Careers: FC<any> = ({location: {state}}) => {
  const ref = useRef(null);
  useEffect(() => {
    if (state && state.vacancy) {
      //@ts-ignore
      ref?.current?.scrollIntoView();
    }
  }, [state, ref]);

  const vacancies = {
    Development: [
      {title: 'DevOps Engineer', path: '/careers/devops'},
      {title: 'Go (Golang, Google Go) Developer', path: '/careers/golang'},
      {title: 'BackEnd (Node.js) Developer', path: '/careers/nodejs'},
      {title: 'FrontEnd (React.js) Developer', path: '/careers/reactjs'},
      {title: 'Senior Software Engineer', path: '/careers/senior-software-engineer'},
    ],
    Support: [{title: 'Customer Success Specialist', path: '/careers/customer-success-specialist'}],
    Marketing: [
      // {title: 'SEO Strategist', path: '/careers/seo-strategist'},
      {title: 'Sales Development Representative (SDR) (English)', path: '/careers/sales-development-representative'},
      // {title: 'Content Writer and Editor (English)', path: '/careers/content-writer-and-editor'},
    ],
  };

  const benefits = [
    {name: 'Medical Insurance', img: insurance},
    {name: 'Annual Salary Review', img: salary},
    {name: 'Days Off', img: daysOff},
    {name: 'Teambuilding and Corporate Events', img: teambuilding},
    {name: 'Flexible Working Schedule', img: flex},
    {name: 'Training Package', img: training},
    {name: 'Stock Options', img: stock},
    {name: 'Annual Bonus', img: bonus},
    {name: 'Lucky Carrot', img: luckyCarrot},
    {name: 'Tea, Coffee and Refreshments', img: coffee},
    {name: 'Reward Systems', img: reward},
    {name: 'Staff Referral Program', img: referral},
  ];

  return (
    <Layout className="careers-container">
      <div className="careers-page-hero-container">
        <div className="careers-page-hero">
          <img
            src="https://storage.googleapis.com/hexact/careers/header/header-img-mobile.webp"
            alt="team img 1"
            className="illustration-mobile"
          />
          <img
            src="https://storage.googleapis.com/hexact/careers/header/header-img-left.webp"
            alt="team img 1"
            className="illustration-1"
          />
          <div className="middle-area">
            <h1>Join our Team</h1>
            <p className="mb-0">Ready to make an impact,</p>
            <p>and change the world?</p>
            <a href="#vacancy-list" className="apply-now-btn">
              Apply Now
            </a>
          </div>
          <img
            src="https://storage.googleapis.com/hexact/careers/header/header-img-right.webp"
            alt="team img 2"
            className="illustration-2"
          />
        </div>
      </div>

      <div className="reasons-section">
        <Row className="section-1 mx-0">
          <Col md={6} className="px-0">
            <div className="content-col">
              <div className="badge-title">REASON 1</div>
              <h2>Diversity at Hexact is about family.</h2>
              <img
                src="https://storage.googleapis.com/hexact/careers/reasons/reason-1.jpg"
                alt="reason 1"
                className="illustrations d-md-none my-2"
              />
              <p className="mb-0">
                Meet your new extended family, working as one with no discrimination, and helping each other grow.
              </p>
              <p>
                We embrace diversity by actions because we believe it leads our company towards innovation, and our
                hiring decisions are solely dependent on talents and personal character.
              </p>
            </div>
          </Col>
          <Col md={6} className="d-none d-md-flex px-0">
            <img
              src="https://storage.googleapis.com/hexact/careers/reasons/reason-1.jpg"
              alt="reason 1"
              className="illustrations"
            />
          </Col>
        </Row>
        <Row className="section-2 mx-0">
          <Col md={6} className="d-none d-md-flex px-0">
            <img
              src="https://storage.googleapis.com/hexact/careers/reasons/reason-2.jpg"
              alt="reason 2"
              className="illustrations"
            />
          </Col>
          <Col md={6} className="px-0">
            <div className="content-col">
              <div className="badge-title">REASON 2</div>
              <h2>Culture does not make people. People make culture.</h2>
              <img
                src="https://storage.googleapis.com/hexact/careers/reasons/reason-2.jpg"
                alt="reason 2"
                className="illustrations d-md-none my-2"
              />
              <p className="mb-0">
                We empower women, appreciate their contributions to our company, and can’t imagine Hexact without its
                female team of developers and professional workers in various departments; they form over 55% of our
                task force. We measure performance by work and commitment, not by race, gender, or color.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="section-3 mx-0">
          <Col md={6} className="px-0">
            <div className="content-col">
              <div className="badge-title">REASON 3</div>
              <h2>We foster inclusion by actions</h2>
              <img
                src="https://storage.googleapis.com/hexact/careers/reasons/reason-3.jpg"
                alt="reason 3"
                className="illustrations d-md-none my-2"
              />
              <p className="mb-0">
                Our team descends from different nations worldwide, and this is how we foster inclusion in our company.
                We need your skills and a positive attitude so we can fulfill our mission of changing the world for the
                better. Are you ready?
              </p>
            </div>
          </Col>
          <Col md={6} className="d-none d-md-flex px-0">
            <img
              src="https://storage.googleapis.com/hexact/careers/reasons/reason-3.jpg"
              alt="reason 3"
              className="illustrations"
            />
          </Col>
        </Row>
      </div>
      <div className="hexact-team d-none d-lg-block"></div>
      <div className="hexact-team-mobile d-lg-none">
        <img src="https://storage.googleapis.com/hexact/careers/team/team-full.webp" alt="team" />
      </div>
      <div className="vacancies-section">
        <div className="text-center mx-auto vacancies">
          <div className="badge-title mt-0">Vacancies</div>
          <h2 className="page-title d-block" id="vacancy-list">
            Take the next step in your career
          </h2>
          <p>We love challenges and changing the status quo. If you feel the same, we look forward to meeting you.</p>
        </div>
        <Row className="vacancies-container mx-0 mx-md-auto" id="vacancy" ref={ref}>
          <Col md={6} className="px-0 px-md-2">
            <div className="department-container blue">
              <div className="department-box">Development {developmentIcon}</div>
              {vacancies.Development.map(item => (
                <Link to={item.path} className="vacancy-box">
                  <h3>{item.title}</h3>
                  <div className="apply-now-btn">Read more</div>
                </Link>
              ))}
            </div>
          </Col>
          <Col md={6} className="px-0 px-md-2">
            <div className="department-container green">
              <div className="department-box green">Support {supportIcon}</div>
              {vacancies.Support.map(item => (
                <Link to={item.path} className="vacancy-box green">
                  <h3>{item.title}</h3>
                  <div className="apply-now-btn">Read more</div>
                </Link>
              ))}
            </div>
            <div className="department-container purple">
              <div className="department-box">Sales & Marketing {marketingIcon}</div>
              {vacancies.Marketing.map(item => (
                <Link to={item.path} className="vacancy-box">
                  <h3>{item.title}</h3>
                  <div className="apply-now-btn">Read more</div>
                </Link>
              ))}
            </div>
          </Col>
        </Row>
      </div>

      <Benefit />
      <div className="life-at-hexact d-md-none">Life at Hexact</div>
      <div className="team-img d-none d-md-block">
        <div className="team-members-top">
          <img src={wall} alt="wall-picture" className="wall-picture" />
          <div className="green-div">
            <img src={green} alt="green-rectangle" className="green" />
          </div>
          <img src={lusine} alt="Lusine" className="lusine" />
          <img src={vahe} alt="Vahe" className="vahe" />
        </div>
        <div>
          <img src={nika} alt="Nika" className="comp-purple-container" />
          <div className="comp-purple">
            <img src={comp} alt="comp" className="comp" />
            <img src={purple} alt="purple-rectangle" className="purple" />
          </div>
          <img src={gevorg} alt="Gevorg" className="gevorg" />
          <div className="brainstorming-hexact">
            <img src={lifeAtHexact} alt="Life at Hexact" className="life-at-hexact" />
            <img src={brainstorming} alt="Brainstorming" />
          </div>
          <div className="yellow-rectangle">
            <img src={stepan} alt="Stepan" />
            <img src={yellow} alt="yellow-rectangle" className="yellow" />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center carousel-wrapper">
        {/* <img
          src="https://storage.googleapis.com/hexact/careers/team/team.webp"
          alt="team"
          className="team-img d-none d-md-block"
        /> */}
        <div className="d-md-none text-center">
          <Carousel>
            {/* <Carousel.Item className="position-relative">
              <img src="https://storage.googleapis.com/hexact/careers/slider/career-slider-1.webp" alt="First slide" />
            </Carousel.Item> */}
            <Carousel.Item className="position-relative">
              <img src="https://storage.googleapis.com/hexact/careers/slider/career-slider-3.webp" alt="Third slide" />
            </Carousel.Item>
            <Carousel.Item className="position-relative">
              <img src="https://storage.googleapis.com/hexact/careers/slider/career-slider-4.webp" alt="Fourth slide" />
            </Carousel.Item>
            <Carousel.Item className="position-relative">
              <img src="https://storage.googleapis.com/hexact/careers/slider/career-slider-2.webp" alt="Second slide" />
            </Carousel.Item>
            <Carousel.Item className="position-relative">
              <img src="https://storage.googleapis.com/hexact/careers/slider/career-slider-5.webp" alt="Fifth slide" />
            </Carousel.Item>
            <Carousel.Item className="position-relative">
              <img src="https://storage.googleapis.com/hexact/careers/slider/career-slider-6.webp" alt="Sixth slide" />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </Layout>
  );
};

export default Careers;
